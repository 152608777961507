
























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Mail extends Vue {
    year = new Date().getFullYear();
}
